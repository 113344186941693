import { TFunction } from 'react-i18next';
import { PopupTableContentMessage } from '../components/common/PopupTableContent';

export function getSubLineDetailMessage(
	t: TFunction,
	{
		operatorName,
		parentOp,
		serverUrl,
		apiWhiteList,
		clientId,
	}: { operatorName: string; parentOp: string; serverUrl: string; apiWhiteList: string; clientId?: string }
): PopupTableContentMessage[] {
	const subLineDetails = [
		{ title: t('management.SubLineRequisition.OperatorName'), content: operatorName || '--' },
		{ title: t('Master'), content: parentOp || '--' },
		{ title: t('management.SubLineRequisition.ServerUrl'), content: serverUrl || '--' },
		{ title: t('management.SubLineRequisition.ApiWhiteList'), content: apiWhiteList || '--' },
	];
	if (clientId) subLineDetails.push({ title: t('management.SubLineRequisition.Client_ID'), content: clientId || '--' });
	return subLineDetails;
}
