import { Pagination } from '@mui/material';
import { useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import api from '../api';
import DetailPopupSection from '../components/common/DetailPopupSection';
import PageTitle from '../components/common/PageTitle';
import { PopupTableContent } from '../components/common/PopupTableContent';
import { DateSelectSection } from '../components/DateSelectSection';
import { detailCol, subLineRequisitionsColumns } from '../components/ManagementTableColumns';
import { SearchWrapper } from '../components/SearchWrapper';
import { Table } from '../components/Table';
import PopupEnum from '../enum/popupEnum';
import { useError } from '../hook/useError';
import { usePage } from '../hook/usePage';
import { useRequestId } from '../hook/useRequestId';
import { RequisitionDataRow } from '../models';
import { usePopupStore } from '../store/popupStore';
import { checkDateRange, formatDate } from '../utils/date';
import { getSubLineDetailMessage } from '../utils/subLine';

const getResultMessage = (t: TFunction, row: any) =>
	getSubLineDetailMessage(t, {
		operatorName: row.operatorName || '',
		parentOp: row.parentOperatorName || '',
		serverUrl: row.serverUrl,
		apiWhiteList: row.apiWhiteList.join(' , '),
		clientId: row.clientId,
	});

export const SubLineRequisitionsPage = () => {
	const { t } = useTranslation();
	const [data, setData] = useState<RequisitionDataRow[]>([]);
	const [startDate, setStartDate] = useState<Date | null>(new Date());
	const [endDate, setEndDate] = useState<Date | null>(new Date());
	const { requestId, updateSearch } = useRequestId(handleSearch);
	const { openPopup, closePopup } = usePopupStore();
	const { handleError } = useError();
	const { currentPage, handlePage, pageSize, pageCount } = usePage(10);

	async function handleSearch(page: number, searchId: string = requestId) {
		try {
			if (!startDate || !endDate) throw PopupEnum.InvalidInput;
			if (checkDateRange(startDate, endDate, 31)) throw PopupEnum.OverSearchDay;
			openPopup(PopupEnum.Loading);
			const res = await api.getSubLineRequisitions({
				startTime: formatDate(startDate),
				endTime: formatDate(endDate),
				currentPage: page,
				pageSize,
				searchId,
			});
			if (!res.rowCount) throw PopupEnum.ResultNotFound;
			handlePage(res.currentPage, res.pageCount);
			setData(res.data);
			closePopup();
		} catch (error: any | PopupEnum) {
			setData([]);
			handleError(error);
		}
	}

	return (
		<div className="space-y-4 w-full">
			<PageTitle content={t('management.SubLineRequisitions.Title')} />
			<SearchWrapper handleSearch={updateSearch}>
				<DateSelectSection
					startDate={startDate}
					setStartDate={setStartDate}
					endDate={endDate}
					setEndDate={setEndDate}
				/>
			</SearchWrapper>
			{!!data?.length && (
				<>
					<Table
						list={data}
						columns={[
							...subLineRequisitionsColumns,
							(t) =>
								detailCol(t, (row) => (
									<DetailPopupSection buttonType="icon">
										<PopupTableContent messageLines={getResultMessage(t, row)} />
									</DetailPopupSection>
								)),
						]}
						initialState={{
							columns: {
								columnVisibilityModel: {
									parentOp: false,
									timeZone: false,
									serverUrl: false,
									apiWhiteList: false,
								},
							},
						}}
					/>
					{!!currentPage && (
						<Pagination
							count={pageCount}
							color="primary"
							page={currentPage}
							onChange={(e, value) => value !== currentPage && handleSearch(value)}
						/>
					)}
				</>
			)}
		</div>
	);
};

export default SubLineRequisitionsPage;
