import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../api';
import { CheckboxSection } from '../components/CheckboxSection';
import PageTitle from '../components/common/PageTitle';
import { Option } from '../components/common/SearchSelect';
import { DateSelectSection } from '../components/DateSelectSection';
import { DownloadExcelButton } from '../components/DownloadExcelButton';
import { OperatorSection } from '../components/OperatorSection';
import { GameProviderSection } from '../components/GameProviderSection';
import { SearchWrapper } from '../components/SearchWrapper';
import { Table } from '../components/Table';
import { TableHeader } from '../components/TableHeader';
import PopupEnum from '../enum/popupEnum';
import { useError } from '../hook/useError';
import { useFile } from '../hook/useFile';
import { DownloadGpWinLoseRequest, SearchWinLoseReportByGameProviderRequest, WinLoseOperatorData } from '../models';
import { usePopupStore } from '../store/popupStore';
import { checkDateRange, formatDate } from '../utils/date';

type Props = {};

export const WinLoseGpPage = (props: Props) => {
	const { t } = useTranslation();
	const [data, setData] = useState<WinLoseOperatorData[]>([]);
	const [startDate, setStartDate] = useState<Date | null>(new Date());
	const [endDate, setEndDate] = useState<Date | null>(new Date());
	const [op, setOp] = useState<Option | null>(null);
	const [gp, setGp] = useState<Option | null>(null);
	const [includeTestAccount, setIncludeTestAccount] = useState(false);
	const [loading, setLoading] = useState(false);
	const { handleFile } = useFile();
	const fileRequest = useRef<DownloadGpWinLoseRequest>();
	const { openPopup, closePopup } = usePopupStore();
	const { handleError } = useError();

	const handleSearch = async () => {
		try {
			if (!startDate || !endDate || !op || !gp) throw PopupEnum.InvalidInput;
			if (checkDateRange(startDate, endDate, 31)) throw PopupEnum.OverSearchDay;
			openPopup(PopupEnum.Loading);
			const req: SearchWinLoseReportByGameProviderRequest = {
				startDate: formatDate(startDate),
				endDate: formatDate(endDate),
				fpId: op.id,
				gameProvider: gp.id,
				includeTestAccount,
			};
			const res = await api.searchWinLoseReportByGameProvider(req);
			if (!res.data.length) throw PopupEnum.ResultNotFound;
			fileRequest.current = req;
			setData(res.data);
			closePopup();
		} catch (error: any | PopupEnum) {
			setData([]);
			handleError(error);
		}
	};

	const downloadGpWinLose = async () => {
		try {
			setLoading(true);

			if (!fileRequest.current) throw PopupEnum.InvalidInput;
			const [excelFile, disposition] = await api.downloadGpWinLose(fileRequest.current);
			handleFile(excelFile, disposition);
		} catch (error) {
			handleError(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="space-y-4">
			<PageTitle content={t('sidebar.report.winlose')} />
			<SearchWrapper handleSearch={handleSearch} showCurrencyHint>
				<DateSelectSection
					startDate={startDate}
					setStartDate={setStartDate}
					endDate={endDate}
					setEndDate={setEndDate}
				/>
				<OperatorSection op={op} setOp={setOp} hasAll />
				<GameProviderSection gp={gp} setGp={setGp} hasAll />
				<CheckboxSection
					isTestAccount={includeTestAccount}
					toggleTestAccount={() => setIncludeTestAccount((value) => !value)}
				/>
			</SearchWrapper>
			{!!data.length && (
				<div className="space-y-12">
					<div className="flex justify-end p-2">
						<DownloadExcelButton download={downloadGpWinLose} isAll loading={loading} />
					</div>
					{data.map(({ winLoseList, ...row }, index) => (
						<Table
							key={index}
							list={winLoseList}
							slots={{
								toolbar: () => <TableHeader info={row} />,
							}}
						/>
					))}
				</div>
			)}
		</div>
	);
};

export default WinLoseGpPage;
