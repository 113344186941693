import { GridColDef } from '@mui/x-data-grid';
import { ReactNode } from 'react';
import { TFunction } from 'react-i18next';
import { I18nStoreType } from '../i18n/react-i18next';
import { formatDateTime } from '../utils/date';

export const requisitionIdCol = (t: TFunction): GridColDef => ({
	field: 'requisitionId',
	headerName: t('management.SubLineRequisition.RequisitionId'),
	type: 'string',
	flex: 1,
	minWidth: 80,
	sortable: false,
});

export const requestOnCol = (t: TFunction): GridColDef => ({
	field: 'requestOn',
	headerName: t('management.SubLineRequisition.RequestOn'),
	type: 'dateTime',
	flex: 2,
	minWidth: 180,
	sortable: false,
	valueFormatter: (value): string => formatDateTime(new Date(value)),
});

export const operatorNameCol = (t: TFunction): GridColDef => ({
	field: 'operatorName',
	headerName: t('management.SubLineRequisition.OperatorName'),
	type: 'string',
	flex: 2,
	minWidth: 160,
	sortable: false,
});

export const requestStatusCol = (t: TFunction): GridColDef => ({
	field: 'requestStatus',
	headerName: t('management.SubLineRequisition.RequestStatus'),
	type: 'string',
	flex: 1,
	minWidth: 120,
	sortable: false,
	valueGetter: (value) => t(`management.SubLineRequisition.${value}` as keyof I18nStoreType),
});

export const userAgentCol = (t: TFunction): GridColDef => ({
	field: 'userAgent',
	headerName: t('management.SubLineRequisition.UserAgent'),
	type: 'string',
	flex: 2,
	minWidth: 160,
	sortable: false,
});

export const authenticationCol = (t: TFunction): GridColDef => ({
	field: 'authentication',
	headerName: t('management.SubLineRequisition.Authentication'),
	type: 'string',
	flex: 3,
	minWidth: 320,
	sortable: false,
});

export const detailCol = (t: TFunction, component: (row: any) => ReactNode): GridColDef => ({
	field: 'detail',
	headerName: t('Detail'),
	type: 'actions',
	flex: 1,
	minWidth: 80,
	sortable: false,
	renderCell: (params) => component(params.row),
});

export const subLineRequisitionsColumns = [
	requisitionIdCol,
	requestOnCol,
	operatorNameCol,
	requestStatusCol,
	userAgentCol,
	authenticationCol,
];

export const accountIdCol = (t: TFunction): GridColDef => ({
	field: 'accountId',
	headerName: t('management.MemberQuery.AccountId'),
	type: 'string',
	flex: 2,
	minWidth: 160,
	sortable: false,
});

export const accountNameCol = (t: TFunction): GridColDef => ({
	field: 'accountName',
	headerName: t('management.MemberQuery.UserName'),
	type: 'string',
	flex: 2,
	minWidth: 160,
	sortable: false,
});

export const agentNameCol = (t: TFunction): GridColDef => ({
	field: 'agentName',
	headerName: t('management.MemberQuery.AgentName'),
	type: 'string',
	flex: 2,
	minWidth: 160,
	sortable: false,
});

export const presentationRoleCol = (t: TFunction): GridColDef => ({
	field: 'presentationRole',
	headerName: t('management.MemberQuery.Permission'),
	type: 'string',
	flex: 2,
	minWidth: 120,
	sortable: false,
});

export const statusCol = (t: TFunction): GridColDef => ({
	field: 'status',
	headerName: t('management.MemberQuery.Status'),
	type: 'string',
	flex: 1,
	minWidth: 80,
	sortable: false,
	valueGetter: (value) => t(`management.MemberForm.${value}` as keyof I18nStoreType),
});

export const lastLoginTimeCol = (t: TFunction): GridColDef => ({
	field: 'lastLoginTime',
	headerName: t('management.MemberQuery.LastLoginTime'),
	type: 'dateTime',
	flex: 2,
	minWidth: 180,
	sortable: false,
	valueFormatter: (value) => formatDateTime(new Date(value)),
});

export const restMemberColumns = [accountNameCol, agentNameCol, presentationRoleCol, statusCol, lastLoginTimeCol];
